import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      filteredBuilding: 'filter/buildingId',
    }),
  },

  watch: {
    filteredBuilding(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterList()
      }
    },
  },

  methods: {
    /**
     * Overwritable method for when the global filters
     * were changed
     */
    filterList() {},
  },
}
